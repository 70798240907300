<div class="container">
    <div class="logo">
        <h1>Registrati</h1>
    </div>
<form (submit)="onRegister()" method="POST" action="http://www.secafservizi.it/ws/controller.php?r=Registrazione">
<div class="row">
    <div class="form-group col">
        <label for="nome">Nome</label>
        <input type="text" class="form-control" id="nome" [(ngModel)]="nome" name="nome" required>
        <small class="form-text text-muted" *ngIf="nomeval">Nome non Valido</small>
    </div>
    <div class="form-group col">
        <label for="cognome">Cognome</label>
        <input type="text" class="form-control" id="cognome" [(ngModel)]="cognome" name="cognome" required>
        <small class="form-text text-muted" *ngIf="cognomeval">Cognome non Valido</small>
    </div>
</div>
<div class="row">
    <div class="form-group col">
        <label for="Email">Indirizzo Email</label>
        <input type="email" class="form-control" id="Email" [(ngModel)]="email" name="email" required
            (blur)="checkMail()">
        <small class="form-text text-muted" *ngIf="mailval">Email non Valida</small>
    </div>
    <div class="form-group col">
        <label for="indirizzo">Indirizzo di fatturazione</label>
        <input type="text" class="form-control" id="indirizzo" [(ngModel)]="indirizzo" name="indirizzo" required>
        <small class="form-text text-muted" *ngIf="indval">Indirizzo non Valido</small>
    </div>
</div>
<div class="row">
    <div class="form-group col">
        <label for="regione">Regione</label>
        <select name="regione" id="regione" [(ngModel)]="regSelez" (click)="getCitta()" required>
            <option *ngFor="let x of regioni" [value]="x.regione">{{x.regione}}</option>
        </select>
        <small class="form-text text-muted" *ngIf="regval">Devi inserire una regione</small>
    </div>
    <div class="form-group col" *ngIf=cittaVisible>
        <label for="citta">Provincia</label>
        <select name="provincia" id="citta" [(ngModel)]="provSelez" required>
            <option *ngFor="let x of citta">{{x.provincia}}</option>
        </select>
        <small class="form-text text-muted" *ngIf="proval">Devi inserire una provincia</small>
    </div>
</div>
    <div class="form-group">
        <label for="Password">Password</label>
        <input type="password" class="form-control" id="Password" aria-describedby="passHelp" [(ngModel)]="password"
            name="password" required>
        <small class="form-text text-muted" *ngIf="passval">Password non Valida</small>
        <ul class="passHelp">
            <li>la password deve contenere dai 6 ai 12 caratteri</li>
            <li>la password deve contenere almeno un carattere speciale</li>
        </ul>
    </div>
    <div class="form-group">
        <label for="Password1">Ripeti Password</label>
        <input type="password" class="form-control" id="Password1" [(ngModel)]="password1" name="password1" required>
        <small class="form-text text-muted" *ngIf="passugual">Le password non Combaciano</small>
    </div>
    <button type="submit" class="btn btn-primary">Registrati</button>
</form>
</div>