import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UtentiService {
  wsRegistrazione = "http://www.secafservizi.it/ws/controller.php?r=Registrazione";
  wsControlloMail = "http://www.secafservizi.it/ws/controller.php?r=ControllaMail";
  wsControlloPassword = "http://www.secafservizi.it/ws/controller.php?r=ControllaPassword";
  wsLogin = "http://www.secafservizi.it/ws/controller.php?r=Login";

  constructor(
    private http:HttpClient
  ) { }

  httpOptions = {
    headers: new HttpHeaders({
 
      'Content-Type': 'application/json',
      responseType: 'json'
    })
  };

  checkMail(request): Observable<any> {
    let params = new HttpParams().set('email', request)
    return this.http.get(this.wsControlloMail, { params })
  }

  checkPassword(request): Observable<any> {
    let params = new HttpParams().set('email', request)
    return this.http.get(this.wsControlloPassword, { params })
  }

  goRegistrazione(utente: any): Observable<any> {
    let params = new HttpParams().set('nome', utente.nome)
      .set('cognome', utente.cognome)
      .set('email', utente.email)
      .set('password', utente.password)
      .set('regione', utente.regione)
      .set('provincia', utente.provincia)
      .set('indirizzo', utente.indirizzo)
    return this.http.post(this.wsRegistrazione, { params });

  }//fine goRegistrazione

  login(email): Observable<any> {
    let params = new HttpParams().set('email', email.email)
    return this.http.post(this.wsLogin, { params }, this.httpOptions)
  }
}
