import { Component, OnInit } from '@angular/core';
import { UtentiService } from '../utenti.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  email: string = "";
  password: string = "";
  controlloMail: boolean = true;
  controlloPassword: boolean = false;

  valpsw: boolean = false;
  constructor(
    private utentiService: UtentiService
  ) { }

  checkMail() {
    this.utentiService.checkMail(this.email).subscribe(
      (data) => {
        if (data) {
          this.controlloMail = true
        } else {
          this.controlloMail = false
        }
      },
      (err) => {
        console.log("capperi!")
      }
    )
  }//checkMail

  checkPassword() {
    this.utentiService.checkPassword(this.email).subscribe(
      (data) => {
        if (data[0]['password'] == this.password) {
          this.controlloPassword = true;
          console.log(this.email)
        } else {
          this.controlloPassword = false;
          console.log(this.controlloPassword)
        }
      }
    )
  }

  onLogin() {
    const email:any = {
      mail: this.email
    }

    if (this.controlloPassword) {
      this.valpsw = false;
      //JSON.parse(email);
      this.utentiService.login(email).subscribe(
        (data) =>
          console.log(data)
      )
    } else {
      this.valpsw = true;
    }


  }
  ngOnInit(): void {
  }

}
