import { Component, OnInit } from '@angular/core';
import { RegioniService } from '../regioni.service';
import { UtentiService } from '../utenti.service';
import { JsonPipe } from '@angular/common';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
  nome: string = "";
  cognome: string = "";
  email: string = "";
  indirizzo: string = "";
  password: string = "";
  password1: string = "";
  regSelez: string = "";
  provSelez: string = "";

  controlloMail: boolean = true;

  regioni: Array<any>;
  citta: Array<any>;

  cittaVisible: boolean = false;
  nomeval: boolean = false;
  cognomeval: boolean = false;
  mailval: boolean = false;
  indval: boolean = false;
  passval: boolean = false;
  passugual: boolean = false;
  regval: boolean = false;
  proval: boolean = false;
  constructor(
    private regioniService: RegioniService,
    private utentiService: UtentiService
  ) { }
  getRegioni() {
    this.regioniService.getRegioni().subscribe(
      (data) => {

        this.regioni = data;
      },
      (err) => {
        console.log(err);
      }
    )
  }// fine getregioni



  getCitta() {
    this.regioniService.getCitta(this.regSelez).subscribe(
      (data) => {
        this.cittaVisible = true;
        this.citta = data
      },
      (err) => {
        console.log("errore");
      }
    )
  }

  checkMail() {
    this.utentiService.checkMail(this.email).subscribe(
      (data) => {
        if (data) {
          this.controlloMail = true
          console.log(this.controlloMail)
        } else {
          this.controlloMail = false
          console.log(this.controlloMail)
        }
      },
      (err) => {
        console.log("capperi!")
      }
    )
  }


  onRegister() {
    var utente:any = {
      nome: this.nome,
      cognome: this.cognome,
      email: this.email,
      regione: this.regSelez,
      provincia: this.provSelez,
      indirizzo: this.indirizzo,
      password: this.password
    }



    if (!(utente.nome.match(/^[a-zA-z ]{2,30}$/)) || utente.nome.trim() == "") {
      this.nomeval = true;
    } else if (!(utente.cognome.match(/^[a-zA-z ]{2,30}$/)) || utente.cognome.trim() == "") {
      this.cognomeval = true;
    } else if (!(utente.email.match(/^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/)) || utente.email.trim() == ""
      || this.controlloMail == true) {
      this.mailval = true;
    } else if (!(utente.password.match(/^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,12}$/)) || utente.password.trim() == "") {
      this.passval = true;
    } else if (this.password1 != utente.password) {
      this.passugual = true;
    } else if (utente.regione.trim() == "") {
      this.regval = true;
    } else if (utente.regione.trim() == "") {
      this.proval = true;
    } else {
      //JSON.parse(utente);
      this.utentiService.goRegistrazione(utente).subscribe(
        (data) => {
          console.log(data)
        }
      )
    }
  }//fine onRegister





  ngOnInit() {
    this.getRegioni();
  }

}
