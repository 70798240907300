<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">
<nav class="navbar navbar-expand-md navbar-light bg-light sticky-top">
    <a class="navbar-brand" [routerLink]="['/home']">SE.CAF</a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup"
        aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarNavAltMarkup">
        <div class="navbar-nav">
            <a class="nav-item nav-link active" [routerLink]="['/home']" [class.active]="step=='step1'" (click)="step='step1'">Home</a>
            <a class="nav-item nav-link" [routerLink]="['/login']" [class.active]="step=='step2'" (click)="step='step2'">Accedi</a>
            <a class="nav-item nav-link disabled" tabindex="-1" aria-disabled="true"
                [routerLink]="['/areariservata']">Area
                Riservata</a>
                <div class="social">
            <a href="#" class="nav-item nav-link fa fa-facebook"></a>
            <a href="#" class="nav-item nav-link fa fa-instagram"></a>
            <a href="#" class="nav-item nav-link fa fa-twitter"></a>
        </div>
        </div>
    </div>
</nav>
