import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RegioniService {
  viewRegioni: string = "http://www.secafservizi.it/ws/controller.php?r=ListaRegioni";
  viewCitta: string = "http://www.secafservizi.it/ws/controller.php?r=GetCitta";
  constructor(
    private http: HttpClient
  ) { }

  getRegioni(): Observable<any> {
    return this.http.get(this.viewRegioni);
  }


  getCitta(request): Observable<any> {
    let params = new HttpParams().set('regione', request)
    return this.http.get(this.viewCitta, { params });
    console.log(request)
  }
}
