<div class="container">
    <div class="logo">
        <h1>Effettua il Login </h1>
    </div>
    <form (submit)='onLogin()' method="post" action="http://localhost/progetto/ws/controller.php?r=Login">
        <div class="form-group">
            <label for="exampleInputEmail1">Indirizzo Email</label>
            <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"
                (blur)="checkMail()" [(ngModel)]="email" name="email">
        </div>
        <div class="form-group">
            <label for="exampleInputPassword1">Password</label>
            <input type="password" class="form-control" id="exampleInputPassword1" [(ngModel)]="password" name="password"
                (blur)="checkPassword()">
            <small class="form-text text-muted" *ngIf="valpsw">Password non Valida</small>
        </div>
        <div class="form-group form-check">
            <input type="checkbox" class="form-check-input" id="exampleCheck1">
            <label class="form-check-label" for="exampleCheck1">Ricorda Credenziali</label>
        </div>
        <button type="button" class="btn btn-link"><a [routerLink]="['/registrati']">Registrati</a></button>
        <button type="submit" class="btn btn-primary">LOGIN</button>
    </form>
    </div>